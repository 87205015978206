<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>Cam kết bảo mật</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/artemisdong/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Ứng dụng Artemis Dong là một ứng dụng miễn phí.  Dịch vụ này được ARTEMIS TECHNOLOGIES AND INVESTMENT COMPANY LIMITED cung cấp miễn phí và sẽ được sử dụng như hiện tại.
Dưới đây bạn có thể tìm thấy thông tin về sản phẩm của chúng tôi:
Hạn mức tín dụng: Tối đa 24,000,000 VNĐ
Thời lượng: 93 đến 365 ngày
Lãi suất: 0.01%-0.08% hằng ngày (lãi suất APR hàng năm lên tới 30%/năm)
Thuế GTGT: 0
Không có phí khác
Trường hợp tính lãi vay của chúng tôi:
Ví dụ: đối với khoản vay 2,000,000 VNĐ phải hoàn trả trong vòng 120 ngày, số tiền vay được tính như sau:
Lãi suất: 2,000,000 VNĐ*0.08%*120= 197,260 VNĐ
Tổng số tiền thanh toán: 2.197.260 VNĐ
Trang này được sử dụng để thông báo cho khách truy cập các chính sách của chúng tôi về việc thu thập, sử dụng và tiết lộ thông tin cá nhân nếu có ai quyết định sử dụng dịch vụ của chúng tôi.
Nếu bạn chọn sử dụng dịch vụ của chúng tôi, bạn đồng ý với việc thu thập và sử dụng thông tin liên quan đến chính sách này.  Thông tin cá nhân chúng tôi thu thập được sử dụng để cung cấp và cải thiện Dịch vụ.  Chúng tôi sẽ không sử dụng hoặc chia sẻ thông tin của bạn với bất kỳ ai ngoại trừ những trường hợp được mô tả trong Chính sách quyền riêng tư này.
Trừ khi được quy định khác trong Chính sách quyền riêng tư này, các điều khoản được sử dụng trong Chính sách quyền riêng tư này có cùng ý nghĩa như trong Điều khoản và Điều kiện của chúng tôi, có thể truy cập được thông qua Ứng dụng Artemis Dong.
Thu thập và sử dụng thông tin
Để có trải nghiệm tốt hơn, khi sử dụng Dịch vụ của chúng tôi, chúng tôi có thể yêu cầu bạn cung cấp cho chúng tôi thông tin nhận dạng cá nhân nhất định.  Thông tin chúng tôi yêu cầu sẽ được chúng tôi lưu giữ và sử dụng như được mô tả trong chính sách bảo mật này.
Vị trí
Chúng tôi sẽ sử dụng dữ liệu vị trí của bạn để:
Cung cấp các dịch vụ và đề xuất được cá nhân hóa dựa trên vị trí.
Xác minh rằng thông tin địa chỉ bạn điền là chính xác.
Đánh giá rủi ro tín dụng của bạn để cung cấp các sản phẩm cho vay chính xác hơn.
Chúng tôi hứa sẽ không theo dõi hoặc lưu dữ liệu vị trí theo thời gian thực của bạn mà không có sự đồng ý rõ ràng của bạn.
Sổ địa chỉ
Chúng tôi sẽ truy cập sổ địa chỉ của bạn để:
Điều này cho phép bạn nhanh chóng thêm các liên hệ khẩn cấp khi đăng ký vay.
Khi tiến hành đánh giá rủi ro, hãy xác minh tính xác thực và hợp lệ của thông tin liên hệ.
Chúng tôi sẽ chỉ thu thập thông tin liên hệ cần thiết và có liên quan, đồng thời đảm bảo rằng thông tin này chỉ được sử dụng cho các mục đích rõ ràng nêu trên và sẽ không được sử dụng cho các mục đích khác.
Máy ảnh
Chúng tôi sẽ sử dụng máy ảnh của bạn để:
Chụp ảnh CMND và các giấy tờ cần thiết khác để xác minh danh tính.
Cung cấp chức năng nhận dạng khuôn mặt để tăng cường bảo mật tài khoản.
Tất cả các bức ảnh được chụp chỉ được sử dụng để xác minh danh tính của bạn và sẽ được xử lý và lưu trữ đúng cách theo yêu cầu của pháp luật.
Album ảnh
Chúng tôi sẽ truy cập thư viện của bạn để:
Tải lên các tài liệu liên quan như CMND và giấy chứng nhận thu nhập để xem xét.
Cho phép bạn tải lên hình đại diện và các thông tin cá nhân khác.
Chúng tôi sẽ chỉ lấy những hình ảnh bạn chọn để tải lên và sẽ không truy cập những hình ảnh khác không được chọn.
Quyền danh sách ứng dụng
Chúng tôi sẽ lấy danh sách ứng dụng của bạn để:
Phân tích việc sử dụng ứng dụng của bạn để đưa ra đề xuất sản phẩm cho vay phù hợp hơn.
Tiến hành đánh giá rủi ro để đảm bảo an toàn và tuân thủ khoản vay.
Chúng tôi hứa sẽ ẩn danh dữ liệu sử dụng ứng dụng của bạn và chỉ sử dụng dữ liệu đó để phân tích thống kê mà không tiết lộ quyền riêng tư cá nhân của bạn.
Ứng dụng sử dụng các dịch vụ của bên thứ ba có thể thu thập thông tin nhận dạng bạn.
Ghi dữ liệu
Chúng tôi xin thông báo với bạn rằng bất cứ khi nào bạn sử dụng dịch vụ của chúng tôi, nếu ứng dụng xảy ra lỗi, chúng tôi sẽ thu thập dữ liệu và thông tin trên điện thoại của bạn (thông qua các sản phẩm của bên thứ ba) (được gọi là dữ liệu nhật ký).  Dữ liệu nhật ký này có thể bao gồm thông tin như địa chỉ Giao thức Internet ("IP") của thiết bị, tên thiết bị, phiên bản hệ điều hành, cấu hình của ứng dụng khi sử dụng Dịch vụ của chúng tôi, ngày và giờ bạn sử dụng Dịch vụ và các số liệu thống kê khác .
Cookie
Cookie là các tệp chứa một lượng nhỏ dữ liệu, thường được sử dụng làm mã định danh duy nhất ẩn danh.  Thông tin này được gửi tới trình duyệt của bạn từ các trang web bạn truy cập và được lưu trữ trong bộ nhớ thiết bị của bạn.
Dịch vụ không sử dụng rõ ràng các "cookie" này.  Tuy nhiên, Ứng dụng có thể sử dụng mã và thư viện của bên thứ ba sử dụng "cookie" để thu thập thông tin và cải thiện dịch vụ của mình.  Bạn có thể chọn chấp nhận hoặc từ chối các cookie này và tìm hiểu thời điểm cookie được gửi đến thiết bị của bạn.  Nếu bạn chọn từ chối cookie của chúng tôi, bạn có thể không sử dụng được một số phần nhất định của Dịch vụ.
nhà cung cấp dịch vụ
Chúng tôi có thể tuyển dụng các công ty và cá nhân bên thứ ba vì những lý do sau:
Để tạo điều kiện thuận lợi cho các dịch vụ của chúng tôi;
Cung cấp dịch vụ thay mặt chúng tôi;
Để thực hiện các dịch vụ liên quan đến Dịch vụ; hoặc
Hãy giúp chúng tôi phân tích việc sử dụng các dịch vụ của chúng tôi.
Chúng tôi muốn thông báo cho người dùng Dịch vụ rằng các bên thứ ba này có thể có quyền truy cập vào thông tin cá nhân của họ.  Lý do là để thay mặt chúng tôi thực hiện nhiệm vụ được giao.  Tuy nhiên, họ có nghĩa vụ không tiết lộ thông tin này hoặc sử dụng nó cho bất kỳ mục đích nào khác.
Sự an toàn
Chúng tôi đánh giá cao sự tin tưởng của bạn trong việc cung cấp cho chúng tôi thông tin cá nhân của bạn, vì vậy chúng tôi đang nỗ lực sử dụng các phương tiện được chấp nhận về mặt thương mại để bảo vệ thông tin đó.  Nhưng hãy nhớ rằng không có phương thức truyền qua Internet hoặc phương pháp lưu trữ điện tử nào an toàn và đáng tin cậy 100% và chúng tôi không thể đảm bảo tính bảo mật tuyệt đối của nó.
Liên kết đến các trang web khác
Dịch vụ có thể chứa các liên kết đến các trang web khác.  Nếu bạn nhấp vào liên kết của bên thứ ba, bạn sẽ được dẫn đến trang web đó.  Xin lưu ý rằng các trang web bên ngoài này không do chúng tôi điều hành.  Vì vậy, chúng tôi đặc biệt khuyên bạn nên xem lại chính sách quyền riêng tư của các trang web này.  Chúng tôi không có quyền kiểm soát và không chịu trách nhiệm về nội dung, chính sách quyền riêng tư hoặc thông lệ của bất kỳ trang web hoặc dịch vụ nào của bên thứ ba.
Quyền riêng tư của trẻ em
Dịch vụ không hướng tới bất kỳ ai dưới 13 tuổi. Chúng tôi không cố ý thu thập thông tin nhận dạng cá nhân từ trẻ em dưới 13 tuổi.  Nếu chúng tôi phát hiện ra rằng một đứa trẻ dưới 13 tuổi đã cung cấp thông tin cá nhân cho chúng tôi, chúng tôi sẽ nhanh chóng xóa thông tin đó khỏi máy chủ của chúng tôi.  Nếu bạn là cha mẹ hoặc người giám hộ và bạn biết rằng con bạn đã cung cấp thông tin cá nhân cho chúng tôi, vui lòng liên hệ với chúng tôi để chúng tôi có thể thực hiện hành động cần thiết.
Những thay đổi đối với Chính sách quyền riêng tư này
Thỉnh thoảng chúng tôi có thể cập nhật chính sách bảo mật của mình.  Vì vậy, bạn nên kiểm tra trang này thường xuyên để biết bất kỳ thay đổi nào.  Chúng tôi sẽ thông báo cho bạn về bất kỳ thay đổi nào bằng cách đăng Chính sách quyền riêng tư mới trên trang này.
liên hệ chúng tôi
Nếu bạn có bất kỳ câu hỏi hoặc đề xuất nào về chính sách quyền riêng tư của chúng tôi, vui lòng liên hệ với chúng tôi bằng cách ký tên.
Website: www.artemisdong.com
Địa chỉ: 08 Tt5.2 Lot, Ao Sao Urban Area, Thinh Liet Ward, Ha Noi
Địa chỉ email: admin@artemisdong.com`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  margin-top: 50px;
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  /* transform: scale(90%); */
  transform: scale(86%);
  margin: -220px auto 0;
  height: 2800px;
  /* box-shadow:0 3px 8px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
.img1{
  margin-top: 50px;
  padding: 0 200px;
}
</style>